import React, { useEffect, useState, useCallback, useRef } from "react"
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
	Col,
	Button,
} from 'reactstrap';
import ForgotPassword_step1 from "../../views/ForgotPassword/ForgotPassword_step1";
import RememberMe from "./RememberMe";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { authenticationService } from '../../jwt/_services';
import SocialLogin from './socialLogin';
import IubendaCookieConsent from '../../components/iubendaCookieConsent';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation, useHistory } from "react-router-dom"


const sidebarBackground = {
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

const Login = () => {

	const { executeRecaptcha } = useGoogleReCaptcha();
	const location  = useLocation();
	const history = useHistory();
	const formRef = useRef();
	const [ reCaptcha, setReCaptcha ] = useState('');
	const [ resetRequired, setResetRequired ] = useState(false);


	const handleClick = () => {
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}

	const handleToLogin = () => {
		var elem = document.getElementById('recoverform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('loginform').style.display = "block";
	}

	const handleReCaptchaVerify = useCallback((event) => {
		if (!executeRecaptcha) {
			console.log('Execute recaptcha not yet available')
			return
		}
		(async () => {
			try {
				const token = await executeRecaptcha('authentication')
				setReCaptcha(token);
				if (formRef.current) {
					formRef.current.handleSubmit();
				}
			} catch (error) {
				console.log(error.response)
			}
		})()
	}, [executeRecaptcha]);

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
		  handleReCaptchaVerify();
		}
	};

	useEffect(() => {
		if (authenticationService.currentUserValue.value) {
			const { from } = location.state || { from: { pathname: "/" } }
			history.push(from)
		}
	}, [location, history]);


	return (
		<div className="">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="auth-wrapper d-flex align-items-center" style={sidebarBackground}>

				<div className="container">
					<div id="loginform">
						<Row className="no-gutters justify-content-center">
							<Col md="6" lg="4" className="bg-primary text-white">
								<div className="p-4">
									<h2 className="display-6">Hi,<br /> 
									<span className="text-cyan font-bold">let's send some loud music out there</span></h2>									
								</div>
							</Col>
							<Col md="6" lg="4" className="bg-white">
								<div className="p-4">
									<h3 className="font-medium mb-3">Log In To Your Account</h3>
									<Formik
										innerRef={formRef}
										initialValues={{
											username: '',
											password: ''
										}}
										validationSchema={Yup.object().shape({
											username: Yup.string().required('Email is required'),
											password: Yup.string().required('Password is required')
										})}
										onSubmit={({ username, password }, { setStatus, setSubmitting }) => {
											setStatus()
											authenticationService.login(username, password, reCaptcha).then(
												(user) => {
													const { from } = location.state || {
														from: { pathname: "/" },
													}
													history.push(from)
												},
												(error) => {
													setSubmitting(false);
													if(error === "Password Reset Required") {
														setResetRequired(true);
														handleClick();
													}
													setStatus(error);
												}
											)
										}}
										render={({ errors, status, touched, isSubmitting }) => (
											<Form className="mt-3" id="loginform">
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ti-email"></i>
														</InputGroupText>
													</InputGroupAddon>

													<Field name="username" type="text" className={'form-control' + (errors.username && touched.username ? ' is-invalid' : '')} onKeyPress={handleKeyPress} />
													<ErrorMessage name="username" component="div" className="invalid-feedback" />
												</InputGroup>
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ti-lock"></i>
														</InputGroupText>
													</InputGroupAddon>
													<Field name="password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} onKeyPress={handleKeyPress} />
													<ErrorMessage name="password" component="div" className="invalid-feedback" />

												</InputGroup>
												<div className="d-flex no-block align-items-center mb-3">
													<RememberMe />
												</div>
												<Row className="mb-3">
													<Col xs="12">
														<Button
															size="lg"
															color="primary"
															block
															onClick={handleReCaptchaVerify}
															disabled={isSubmitting}
														>
															Login
														</Button>
													</Col>
												</Row>
												{status &&
													<div className={'alert alert-danger'}>{status}</div>
												}
												{/* <div className="justify-content-center d-flex align-items-center">
													Don't have an account? <a href="/authentication/signup" style={{paddingLeft: 6}} className="forgot text-cyan float-right">Register</a>
												</div> */}
												<div className="justify-content-center d-flex align-items-center">
													Forgot password? <a href="#recoverform" id="to-recover" style={{paddingLeft: 6}} onClick={handleClick} className="forgot text-cyan float-right">Reset</a>
												</div>

												<hr/>
												<h4 className="text-center text-bold">Or</h4>
												<SocialLogin />
											</Form>
										)}
									/>
								</div>

							</Col>
						</Row>
					</div>
					<div id="recoverform">
						<ForgotPassword_step1 handleToLogin={handleToLogin} resetRequired={resetRequired} />
						<IubendaCookieConsent />
					</div>
				</div>
			</div>
			<div id="recaptcha-container"></div>
		</div>
	)
};

export default Login;