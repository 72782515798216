import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Alert } from "reactstrap";
import PromoItem from "../../components/promoItem";
import UserWelcome from "../../components/userWelcome";
import UserStatementsGraph from "../../components/userStatementsGraph";
import SubuserStatementsGraph from "../../components/subuserStatementsGraph";
import { StateContext } from "../../utils/context";
import UserZohoASAPWidget from "../../components/userZohoASAPWidget";
import SubUserZohoASAPWidget from "../../components/subuserZohoASAPWidget";
import { getCookie } from "../../jwt/_helpers";
import { useFetch } from "../../utils/fetchHook";
import { Link } from "react-router-dom/cjs/react-router-dom";

const Dashboard = () => {
  const {currentUser} = useContext(StateContext);
  const token = getCookie("token");
  const [promoFeedbackCount, setPromoFeedbackCount] = useState(0);
  const [promoDownloadCount, setPromoDownloadCount] = useState(0);
  const [zohoJWT] = useFetch( "GET", `zoho-jwt/`, token, false);

  const [zohoJWTToken, zohoJWTTokenSET] = useState(null);

  const [showSubmitInfoMsg, setShowSubmitInfoMsg] = useState(false);

  useEffect(() => {
    if(zohoJWT && zohoJWT.token)
    {
      zohoJWTTokenSET(zohoJWT.token);
    }
  }, [zohoJWT]);

  useEffect(() => {
    if (currentUser) {
      setPromoFeedbackCount(currentUser.promo_feedback_count);
      setPromoDownloadCount(currentUser.promo_download_count);
      if (currentUser && currentUser.hasOwnProperty('identity_checked') && !currentUser.is_sub_user) {
        setShowSubmitInfoMsg(true);
      } else {
        setShowSubmitInfoMsg(false);
      }    
    }
  }, [currentUser]);

  return (
    <>
      <Row>
        <Col>
          <UserWelcome />
        </Col>
      </Row>

      {
        showSubmitInfoMsg &&
        <Row>
          <Col>
            <Alert color="info">
              You have not submitted your information yet, please <Link to="/complete-information"><b>Complete Your Information</b></Link> to proceed further!
            </Alert>
          </Col>
        </Row>
      }
      
      <Row>
        <Col>
          {
            (currentUser && currentUser.is_sub_user) && 
            <>
              <SubuserStatementsGraph />
              <SubUserZohoASAPWidget token={zohoJWTToken} />
            </>
          }
          {
            (currentUser && !currentUser.is_sub_user) && 
            <>
              <UserStatementsGraph />
              <UserZohoASAPWidget token={zohoJWTToken} />
            </>
          }
        </Col>
      </Row>

      <Row>
        <PromoItem
          itemIcon="ti-pie-chart"
          itemHeading="Promo download count"
          itemSubHeading="Total downloads"
          itemCount={promoDownloadCount}
          cardBg="#707cd2"
        />
        <PromoItem
          id="2"
          itemIcon="icon-cloud-download"
          itemHeading="Promo feedback count"
          itemSubHeading="Total feedbacks"
          itemCount={promoFeedbackCount}
          cardBg="#ff5050"
        />
      </Row>
    </>
  );
};

export default Dashboard;
